'use client';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslations } from 'next-intl';
import { useIsVisibleOnce } from '@/hooks/useIsVisibleOnce';
import { cn } from '@/lib/tailwind';
import { AnyObject } from '@/types';
import { usePageContext } from '@/hooks/usePageContext';
import { isWindowDefined } from '@/utils/isWindowDefined';
import { DEFAULT_LANGUAGE_CONFIG } from '@/constants/locales';
import RichText from '@/components/richText';
import useNavatticDemoLoader from '../CallToAction/hooks/useNavatticDemoLoader';
import { createHubspotScript } from './scripts';
import FormSkeleton from './components/FormSkeleton';
import { Props } from './types';
import { useEmailableValidation } from './hooks/useEmailableValidation';
import {
  formAccessibilityHelper,
  getFormId,
  getLocalizedSlug,
  getSubmitAction,
} from './utils';
import { EmailableStatus } from './components/EmailableStatus';
import { handleFormEvents } from './utils/handleFormEvents';
import './styles.css';

const Form = ({
  headline,
  subHeadline,
  id,
  externalValidId,
  formType,
  ctaLabel,
  sfCampaign,
  alternateFormId,
  customFormId,
  redirectSlug,
  externalRedirectUrl,
  assetId,
  on24_id,
  on24_key,
  onSubmitComplete,
  isModal = false,
  isBordered = false,
  emailDomainsRestrictionRegex,
  privacyPolicyText,
  navatticDemoConfig,
}: Props) => {
  //isLoaded is needed to trigger a re-render so that the dropdown translations work when switching languages.
  const [isLoaded, setIsLoaded] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [_phoneError, setPhoneError] = useState(false);
  const [isAutoCompleted, setIsAutoCompleted] = useState(false);
  const [_isButtonSpinning, setIsButtonSpinning] = useState(false);
  const isNavatticScriptLoaded = useNavatticDemoLoader(
    navatticDemoConfig,
    'OPEN_NAVATTIC_DEMO',
  );

  const t = useTranslations();

  const formRef = useRef<HTMLDivElement | null>(null);
  const timerRef = useRef<AnyObject>(0);
  const scriptCreated = useRef(false);

  const isNavatticScriptLoadedRef = useRef(isNavatticScriptLoaded);
  useEffect(() => {
    isNavatticScriptLoadedRef.current = isNavatticScriptLoaded;
  }, [isNavatticScriptLoaded]);

  // TODO: change locale to prefixSlug
  const { locale = DEFAULT_LANGUAGE_CONFIG.prefixSlug, productCategory } =
    usePageContext();

  const { isVisible, setRef } = useIsVisibleOnce();

  const { data, status } = useEmailableValidation({
    formRef,
    isHubspotFormLoaded: isLoaded,
    emailDomainsRestrictionRegex,
  });

  const rawFormId = externalValidId || `form-${id}`;

  const validId = isModal ? `modal-${rawFormId}` : rawFormId;

  const validIdSelector = `#${validId}`;
  const isRequestMeetingForm = formType === 'request_meeting';

  const localizedSlug = useMemo(
    () =>
      getLocalizedSlug({
        productCategory,
        locale,
        redirectSlug,
        formType,
      }),
    [productCategory, locale, redirectSlug, formType],
  );

  const formId = useMemo(
    () => getFormId({ formType, alternateFormId, customFormId }),
    [formType, alternateFormId, customFormId],
  );

  const submitAction = useMemo(
    () => getSubmitAction(redirectSlug, localizedSlug),
    [redirectSlug, localizedSlug],
  );

  useEffect(() => {
    window.addEventListener('onSubmitStart', () => setIsButtonSpinning(true));
    return () => window.removeEventListener('onSubmitStart', () => {});
  }, []);

  useEffect(() => {
    const createScripts = () => {
      clearTimeout(timerRef.current);

      createHubspotScript({
        formId,
        sfCampaign,
        validId,
        validIdSelector,
        submitAction,
        ctaLabel,
        privacyPolicyText,
        t,
      });
    };

    if (isVisible && !scriptCreated.current) {
      scriptCreated.current = true;
      createScripts();
    }

    timerRef.current = setTimeout(() => {
      if (!scriptCreated.current) {
        scriptCreated.current = true;
        createScripts();
      }
    }, 25 * 1000);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const openNavatticDemo = useCallback(() => {
    const isLoaded = isNavatticScriptLoadedRef.current;
    if (isLoaded && navatticDemoConfig?.popupUrl) {
      const { popupUrl, title } = navatticDemoConfig;
      window.NavatticEmbed.initPopup(popupUrl, { title });
      window.NavatticEmbed.openPopup(popupUrl);
    }
  }, [navatticDemoConfig]);

  useEffect(() => {
    // TODO: Add a cleanup function to remove the event listener
    if (isWindowDefined()) {
      handleFormEvents({
        formType,
        validId,
        validIdSelector,
        sfCampaign,
        locale,
        assetId,
        on24_id,
        on24_key,
        isRequestMeetingForm,
        onSubmitComplete,
        setDisplayName,
        setIsAutoCompleted,
        setIsButtonSpinning,
        setIsLoaded,
        setPhoneError,
        externalRedirectUrl,
        isSubmittedForm: false,
        t,
        openNavatticDemo,
      });
    }
  }, [locale]); // eslint-disable-line react-hooks/exhaustive-deps

  // Screen readers read privacy policy text and hide that it is hacked together as a list item.
  useEffect(() => {
    formAccessibilityHelper(formRef);
  }, [formRef]);

  const combinedFormRef = useCallback(
    (el: any) => {
      formRef.current = el;
      setRef(el);
    },
    [setRef, formRef],
  );

  // Allows Not You? button to override autocomplete logic
  const autoCompleteDisengager = useCallback(() => {
    setIsAutoCompleted(false);
  }, []);

  return (
    <div ref={combinedFormRef}>
      <div
        className={cn(
          'bg-white rounded-[8px] p-6 tablet:p-8',
          isModal && 'shadow-form',
          'p-4 tablet-sm:p-10 desktop-sm:p-6 desktop-lg:p-8',
          !isModal && 'tablet-sm:p-6',
          isBordered && 'border border-lightGrey',
        )}
      >
        <div className={'mb-4 tablet-sm:mb-8 desktop-sm:mb-4 desktop-lg:mb-6'}>
          {headline ? (
            <p
              className={cn(
                'text-mobile-sm/body-3-bold tablet-sm:text-tablet-sm/heading-5 desktop-sm:text-tablet-sm/body-2-bold desktop-lg:text-desktop-large/heading-4',
              )}
            >
              {headline}
            </p>
          ) : null}
          {subHeadline ? (
            <RichText
              richText={subHeadline}
              className={cn(
                'mt-1 desktop-lg:mt-2 text-cometBlack',
                'text-mobile-sm/body-4 tablet-sm:text-tablet-sm/body-3 desktop-sm:text-tablet-sm/body-4 desktop-lg:text-desktop-large/body-2',
              )}
            />
          ) : null}
        </div>

        <div className={cn(isAutoCompleted ? 'block' : 'hidden')}>
          <p className="font-bold text-[20px] leading-[1.4] tablet:text-[32px]">
            <span>{t('welcomeBackMessage', { name: displayName })}</span>
          </p>
          <button
            onClick={autoCompleteDisengager}
            className="bg-none border-none pl-0 pb-4 text-[16px] cursor-pointer text-[#185ad2] underline transition-colors outline-none hover:text-primaryOceanBlue"
          >
            {t(`Not you?`)}
          </button>
          <p className="leading-[1.6] mb-4">
            {t(`No need to fill out any forms — you're all set`)}
          </p>
        </div>

        <div id={validId} />

        {isLoaded ? null : <FormSkeleton />}
      </div>

      <EmailableStatus formRef={formRef} status={status} data={data} />
    </div>
  );
};

export default Form;
