import { Dispatch } from 'react';
import { AnyObject } from '@/types';
import { getCountryLabel } from './country';
import { handleHiddenFields } from './handleHiddenFields';
import { getAutoCompleteStatus } from './getAutoCompleteStatus';
import {
  getIsSubmittedForm,
  handleOn24Submission,
  translateDropdownOptions,
} from '.';

type Props = {
  validId: string;
  validIdSelector: string;
  assetId?: string;
  on24_id: string;
  on24_key: string;
  formType?: string;
  sfCampaign: string;
  externalRedirectUrl?: string;
  locale?: string;
  isSubmittedForm?: boolean;
  isRequestMeetingForm?: boolean;
  onSubmitInit?: (status: boolean) => void;
  onSubmitComplete?: (status?: boolean) => void;
  setIsLoaded: Dispatch<React.SetStateAction<boolean>>;
  setPhoneError: Dispatch<React.SetStateAction<boolean>>;
  setIsAutoCompleted: Dispatch<React.SetStateAction<boolean>>;
  setDisplayName: Dispatch<React.SetStateAction<string>>;
  setIsButtonSpinning: Dispatch<React.SetStateAction<boolean>>;
  setSubmitEventHasTriggered?: Dispatch<React.SetStateAction<boolean>>;
  // TODO: fix types
  t: AnyObject;
  openNavatticDemo: () => void;
};

const prefillValues = (validIdSelector: string) => {
  const countrySelector = `${validIdSelector} select[name=country_dropdown]`;
  const countryEl: HTMLInputElement = document.querySelector(countrySelector)!;
  if (countryEl) {
    getCountryLabel().then(country => {
      countryEl.value = country;
      const event = new Event('input', { bubbles: true, cancelable: true });
      countryEl.dispatchEvent(event);
    });
  }
};

// TODO: refractor logic
export const handleFormEvents = ({
  formType,
  on24_id,
  on24_key,
  sfCampaign,
  assetId,
  externalRedirectUrl,
  onSubmitInit,
  onSubmitComplete,
  validId,
  isSubmittedForm,
  setIsLoaded,
  validIdSelector,
  setPhoneError,
  isRequestMeetingForm,
  setIsAutoCompleted,
  setDisplayName,
  setIsButtonSpinning,
  setSubmitEventHasTriggered,
  t,
  locale,
  openNavatticDemo,
}: Props) => {
  let submittedFormValue: AnyObject;

  window.addEventListener('message', event => {
    const eventType = event?.data?.type;
    if (
      eventType === 'hsFormCallback' &&
      event.data.eventName === 'onFormReady'
    ) {
      handleHiddenFields({
        validIdSelector,
        setPhoneError,
        formType,
        on24_id,
        on24_key,
        validId,
        sfCampaign,
        assetId,
      });
      prefillValues(validIdSelector);
      //determine if hubspot already knows this person
      const { allAutoCompleted, firstNameAutocomplete } =
        getAutoCompleteStatus(validIdSelector);
      allAutoCompleted &&
        !isRequestMeetingForm &&
        setIsAutoCompleted(allAutoCompleted);
      allAutoCompleted && setDisplayName(firstNameAutocomplete as string);

      // TODO: i18n: translate dropdown options and alphabetize the translations
      if (locale !== 'us') {
        translateDropdownOptions(t);
      }
      setIsLoaded(true);
    }

    if (
      eventType === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmit'
    ) {
      const data = event?.data?.data;
      isSubmittedForm = getIsSubmittedForm(submittedFormValue, data, validId);
      isSubmittedForm && onSubmitInit && onSubmitInit(isSubmittedForm);
      //if this is the form that was submitted redirect if it's supposed to
      isSubmittedForm &&
        externalRedirectUrl &&
        window.open(externalRedirectUrl);

      isSubmittedForm && openNavatticDemo();

      handleOn24Submission(data, on24_id, on24_key);
    }

    if (
      eventType === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted'
    ) {
      // this renders the thank you message in the parent div, for forms embedded on pages and in modals
      onSubmitComplete?.(isSubmittedForm);
      // When this event fires stop spinning
      setIsButtonSpinning(false);
      setSubmitEventHasTriggered && setSubmitEventHasTriggered(true);
    }

    if (event.data && event.data.message === 'LD_FORM_VALIDATION_ERROR')
      setIsButtonSpinning(false);
  });
};
