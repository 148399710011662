import { useEffect, useState } from 'react';

export const useIsVisibleOnce = () => {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const isIntersectionObserverAvailable =
    typeof window === 'undefined' ? false : !!window.IntersectionObserver;
  const [isVisible, setIsVisible] = useState(
    isIntersectionObserverAvailable ? false : true,
  );

  useEffect(() => {
    if (isIntersectionObserverAvailable) {
      const observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            return;
          }
        },
        {
          root: null,
          threshold: 0.1,
          rootMargin: '600px',
        },
      );

      if (ref) {
        observer.observe(ref);
      }

      return () => {
        if (ref) {
          observer.unobserve(ref);
        }
      };
    }
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  return { isVisible, setRef };
};
